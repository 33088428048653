@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{margin:0;padding:0;box-sizing:border-box;font-family:"Raleway", sans-serif}p{color:#525f7f}.app__jumbotron{width:100%;background:linear-gradient(237.38deg, #fff 0%, #f4f5f7 100%);padding-top:5%;padding-bottom:5%}.app__jumbotronSmall{background:rgba(46,213,115,0.1);border-radius:50px;width:80%;max-width:1200px;margin-right:auto;margin-left:auto;padding:3%}.btn__blue{background:#1e90ff;border-radius:4px;border:none;color:white;padding:13px 25px;text-align:center;text-decoration:none;display:inline-block;font-size:16px;cursor:pointer;max-width:200px}.app__spacer{height:100px}.app__divider{height:1px;background:rgba(0,0,0,0.2);width:100%}@media screen and (max-width: 768px){.app__jumbotronSmall{width:90%;padding:5%}.app__spacer{height:50px}}

.home__main{width:90%;max-width:1200px;margin-right:auto;margin-left:auto;display:grid;grid-template-columns:6fr 6fr;grid-column-gap:50px}.home__jumboImg{width:90%}.home__mainDesc{display:flex;flex-direction:column;justify-content:space-around}.home__mainDesc h1{font-weight:bold;font-size:50px}.home__mainDesc p{line-height:1.6;font-size:20px}.home__subDesc{display:flex;flex-direction:column;justify-content:space-around}.home__subDesc h1{font-weight:bold;font-size:40px}.home__subDesc p{line-height:1.6;font-size:16px}.home__whyChoose{display:flex;flex-direction:column;width:90%;max-width:1200px;margin-right:auto;margin-left:auto}.home__whyChoose h1{font-size:40px}.home__whyChooseGrid{display:grid;grid-template-columns:6fr 6fr;grid-column-gap:50px;align-items:center}.home__whyChooseGrid img{width:100%}.home__whyChooseDesc h2{font-size:24px;font-weight:bold;font-style:normal;margin-bottom:30px}.home__whyChooseDesc p{font-size:16px;line-height:1.6}@media screen and (max-width: 768px){.home__main{grid-template-columns:12fr}.home__jumboImg{margin:auto;width:80%}.home__mainDesc h1{font-weight:bold;font-size:40px}.home__mainDesc p{margin-top:50px;line-height:1.6;font-size:20px}.home__mainDesc input{margin-top:50px;margin-bottom:50px}.home__subDesc h1{margin-top:50px}.home__subDesc p{margin-top:50px;line-height:1.6;font-size:20px}.home__whyChoose h1{font-size:32px;text-align:center}.home__whyChooseGrid{display:grid;grid-template-columns:12fr;grid-column-gap:50px;align-items:center}.home__whyChooseGrid img{width:100%}.home__whyChooseDesc h2{margin-top:30px;text-align:center}.home__whyChooseDesc p{text-align:center}}

.navbar{padding-top:10px;padding-bottom:10px;position:-webkit-sticky;position:sticky;top:0;background:white;z-index:10;transition:all 0.3s}.navbar.onScroll{box-shadow:0 2px 4px 0 rgba(0,0,0,0.2),0 3px 10px 0 rgba(0,0,0,0.19)}.navbar__links{display:flex;list-style:none;height:100%;align-items:center}.navbar__all{display:flex;height:100%;width:80%;max-width:1200px;margin:auto;align-items:center}.navbar__logo{z-index:5}.navbar__logo img{height:10vh;transition:all 0.3s}.navbar__link{color:#212529;text-decoration:none;font-size:18px;font-weight:400;margin-left:50px;line-height:22px;border-bottom:none;padding-bottom:4px}.navbar__selected{color:#1e90ff;font-weight:bold;border-bottom:1px solid #1e90ff;padding-bottom:5px}@media screen and (max-width: 768px){.navbar__all{justify-content:space-between}.navbar__line{width:30px;height:3px;background:#212529;margin:5px;transition:all 0.3s}.navbar__hamburger{cursor:pointer;z-index:2}.navbar__hamburger.open .navbar__line:nth-child(1){transform:rotate(45deg) translateY(5px)}.navbar__hamburger.open .navbar__line:nth-child(2){display:none}.navbar__hamburger.open .navbar__line:nth-child(3){transform:rotate(-45deg) translateY(-5px) translateX(1px)}.navbar__links{position:fixed;top:0;left:0;right:0;bottom:0;background:white;flex-direction:column;transition:all 0.5s ease-out;pointer-events:none;justify-content:center;display:none}.navbar__link{display:block;margin-top:25px;margin-bottom:25px;margin-left:0px;font-size:24px;font-weight:500}.navbar__links.open{display:flex;pointer-events:all}.navbar__logo img{height:7vh}}

.footer{display:grid;grid-template-columns:1fr 1fr;grid-column-gap:50px;background:#212529;width:100%;padding:2%;color:white}.footer__row{display:flex;flex-direction:column;justify-content:space-evenly}.footer__row img{width:75px;margin-bottom:10px}.footer__row p{color:white;font-size:14px;line-height:28px;margin-bottom:10px}.left{align-items:flex-end}.footer__smList{font-size:24px}.footer__sm{margin-left:24px;cursor:pointer}.footer__sm:hover{color:#b6a7a7}@media screen and (max-width: 768px){.footer{grid-template-columns:1fr;padding:5%}.footer__row img{width:48px;margin-right:auto;margin-left:auto}.footer__row p{text-align:center;font-size:12px}.left{align-items:center}}

.about__desc{display:flex;flex-direction:column;justify-content:space-around}.about__desc h3{font-size:24px;line-height:1.6;margin-bottom:10px}.about__desc h1{font-weight:bold;font-size:40px;margin-bottom:50px}.about__desc p{line-height:1.6;font-size:16px}.about__team{width:90%;max-width:1200px;margin-right:auto;margin-left:auto}.about__teamDesc h1{text-align:center;margin-bottom:30px;font-size:40px}.about__teamDesc p{text-align:center;line-height:1.6}.about__teamMembers{display:grid;grid-template-columns:4fr 4fr 4fr;grid-column-gap:50px;margin:auto}.about__member{display:flex;flex-direction:column}.about__member img{margin-right:auto;margin-left:auto;width:144px;margin-bottom:10px}.about__member h3{text-align:center;line-height:1.6;font-size:24px}.about__member p{text-align:center;line-height:1.6;margin-bottom:50px}.about__mv{width:90%;max-width:1200px;margin-right:auto;margin-left:auto;display:grid;grid-template-columns:6fr 6fr;grid-column-gap:50px}.about__mvItem{display:flex;flex-direction:column}.about__mvItem h1{font-size:40px;line-height:1.6;margin-bottom:30px}.about__mvItem p{font-size:16px;line-height:1.6}@media screen and (max-width: 768px){.about__desc h1{margin-bottom:20px}.about__teamMembers{grid-template-columns:12fr;grid-column-gap:0px}.about__mv{grid-template-columns:12fr;grid-column-gap:0px}.about__mvItem h1{text-align:center;margin-bottom:10px}.about__mvItem p{text-align:center}.vision{margin-top:50px}}

.ourCraft__tpr{color:blueviolet;text-decoration:underline;cursor:pointer}.ourCraft__apps{width:90%;max-width:1200px;margin-right:auto;margin-left:auto;display:grid;grid-template-columns:6fr 6fr;grid-column-gap:50px}.ourCraft__appItem{display:flex;flex-direction:column}.ourCraft__appItem img{width:70%;margin-right:auto;margin-left:auto}.ourCraft__appItem h1{text-align:center;font-size:40px;line-height:1.6}.ourCraft__appItem p{text-align:center;font-size:16px;line-height:1.6}.ourCraft__appItem a{margin:0 auto}.ourCraft__appItem a img{width:100%}.ourCraft__button{margin-top:20px;cursor:pointer}@media screen and (max-width: 768px){.ourCraft__apps{grid-template-columns:12fr;grid-column-gap:0px}.ourCraft__appItem img{width:90%}}

.cus{width:90%;max-width:800px;margin-right:auto;margin-left:auto}.cus__title h1{text-align:center;line-height:1.6;font-size:40px}.cus__title p{text-align:center;line-height:1.6}.cus__row{display:grid;grid-template-columns:6fr 6fr;grid-column-gap:50px}.cus__col{margin-top:20px}.cus__col h3{line-height:1.6}.cus__col input{background:#f2f2f2;border:none;border-radius:4px 4px 0px 0px;border-bottom:1px solid rgba(0,0,0,0.2);width:100%;height:50px;padding-right:15px;padding-left:15px}.cus__col textarea{background:#f2f2f2;border:none;border-radius:4px 4px 0px 0px;border-bottom:1px solid rgba(0,0,0,0.2);width:100%;height:100px;padding:15px}.btn__send{display:block;margin-right:auto;margin-left:auto;margin-top:30px}@media screen and (max-width: 768px){.cus__row{grid-template-columns:12fr;grid-column-gap:0px}}

.expertise{width:90%;max-width:1200px;margin-right:auto;margin-left:auto}.expertise__container{width:90%;max-width:800px;margin:0 auto;display:flex;flex-direction:column}.expertise__container img{margin:0 auto;width:auto;z-index:2}.expertise__card{margin-top:-15%;padding:7%;height:100%;padding-top:20%;box-shadow:0px 15px 35px rgba(50,50,93,0.1),0px 5px 15px rgba(0,0,0,0.07);border-radius:15px}.expertise__card h3{text-align:center;font-size:24px;line-height:1.6;margin-bottom:10px}.expertise__card p{font-size:16px;line-height:1.6}.expertise__row{display:grid;grid-template-columns:6fr 6fr;grid-column-gap:50px}@media screen and (max-width: 768px){.expertise__row{grid-template-columns:12fr;grid-column-gap:0px}}

.contactUs{width:90%;max-width:1000px;margin-right:auto;margin-left:auto}.contactUs h1{text-align:center;font-size:40;font-weight:bold;margin-bottom:30px}.big h1{font-size:50px;line-height:1.6}.contactUs__social{display:grid;grid-template-columns:4fr 8fr;margin-bottom:30px}.contactUs__social img{margin-left:auto;margin-right:10px}.social__desc{display:flex;flex-direction:column;justify-content:space-around}.social__desc h4{line-height:1.6;cursor:pointer;color:#1e90ff}.social__desc p{line-height:1.6}@media screen and (max-width: 768px){.big h1{font-size:40px;line-height:1.6}.contactUs__social{grid-template-columns:2fr 8fr}}


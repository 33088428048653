@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
}

p {
  color: #525f7f;
}

.app__jumbotron {
  width: 100%;
  background: linear-gradient(237.38deg, #ffffff 0%, #f4f5f7 100%);
  padding-top: 5%;
  padding-bottom: 5%;
}

.app__jumbotronSmall {
  background: rgba(46, 213, 115, 0.1);
  border-radius: 50px;
  width: 80%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding: 3%;
}

.btn__blue {
  background: #1e90ff;
  border-radius: 4px;
  border: none;
  color: white;
  padding: 13px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  max-width: 200px;
}

.app__spacer {
  height: 100px;
}

.app__divider {
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
}

@media screen and (max-width: 768px) {
  .app__jumbotronSmall {
    width: 90%;
    padding: 5%;
  }

  .app__spacer {
    height: 50px;
  }
}

.contactUs {
  width: 90%;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;

  h1 {
    text-align: center;
    font-size: 40;
    font-weight: bold;
    margin-bottom: 30px;
  }
}

.big {
  h1 {
    font-size: 50px;
    line-height: 1.6;
  }
}

.contactUs__social {
  display: grid;
  grid-template-columns: 4fr 8fr;
  margin-bottom: 30px;

  img {
    margin-left: auto;
    margin-right: 10px;
  }
}

.social__desc {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  h4 {
    line-height: 1.6;
    cursor: pointer;
    color: #1e90ff;
  }

  p {
    line-height: 1.6;
  }
}

@media screen and (max-width: 768px) {
  .big {
    h1 {
      font-size: 40px;
      line-height: 1.6;
    }
  }

  .contactUs__social {
    grid-template-columns: 2fr 8fr;
  }
}

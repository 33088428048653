.home__main {
  width: 90%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-column-gap: 50px;
}

.home__jumboImg {
  width: 90%;
}

.home__mainDesc {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  h1 {
    font-weight: bold;
    font-size: 50px;
  }

  p {
    line-height: 1.6;
    font-size: 20px;
  }
}

.home__subDesc {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  h1 {
    font-weight: bold;
    font-size: 40px;
  }

  p {
    line-height: 1.6;
    font-size: 16px;
  }
}

.home__whyChoose {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;

  h1 {
    font-size: 40px;
  }
}

.home__whyChooseGrid {
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-column-gap: 50px;
  align-items: center;

  img {
    width: 100%;
  }
}

.home__whyChooseDesc {
  h2 {
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    margin-bottom: 30px;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
  }
}

@media screen and (max-width: 768px) {
  .home__main {
    grid-template-columns: 12fr;
  }

  .home__jumboImg {
    margin: auto;
    width: 80%;
  }

  .home__mainDesc {
    h1 {
      font-weight: bold;
      font-size: 40px;
    }

    p {
      margin-top: 50px;
      line-height: 1.6;
      font-size: 20px;
    }

    input {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  .home__subDesc {
    h1 {
      margin-top: 50px;
    }

    p {
      margin-top: 50px;
      line-height: 1.6;
      font-size: 20px;
    }
  }

  .home__whyChoose {
    h1 {
      font-size: 32px;
      text-align: center;
    }
  }

  .home__whyChooseGrid {
    display: grid;
    grid-template-columns: 12fr;
    grid-column-gap: 50px;
    align-items: center;
  
    img {
      width: 100%;
    }
  }

  .home__whyChooseDesc {
    h2 {
      margin-top: 30px;
      text-align: center;
    }
  
    p {
      text-align: center;
    }
  }
}

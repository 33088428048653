.expertise {
  width: 90%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.expertise__container {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  img {
    margin: 0 auto;
    width: auto;
    z-index: 2;
  }
}

.expertise__card {
  margin-top: -15%;
  padding: 7%;
  height: 100%;
  padding-top: 20%;
  box-shadow: 0px 15px 35px rgba(50, 50, 93, 0.1),
    0px 5px 15px rgba(0, 0, 0, 0.07);
  border-radius: 15px;

  h3 {
    text-align: center;
    font-size: 24px;
    line-height: 1.6;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
  }
}

.expertise__row {
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-column-gap: 50px;
}

@media screen and (max-width: 768px) {
  .expertise__row {
    grid-template-columns: 12fr;
    grid-column-gap: 0px;
  }
}

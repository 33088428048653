.cus {
  width: 90%;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

.cus__title {
  h1 {
    text-align: center;
    line-height: 1.6;
    font-size: 40px;
  }
  p {
    text-align: center;
    line-height: 1.6;
  }
}

.cus__row {
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-column-gap: 50px;
}

.cus__col {
  margin-top: 20px;

  h3 {
    line-height: 1.6;
  }

  input {
    background: #f2f2f2;
    border: none;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 50px;
    padding-right: 15px;
    padding-left: 15px;
  }

  textarea {
    background: #f2f2f2;
    border: none;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100px;
    padding: 15px;
  }
}

.btn__send {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .cus__row {
    grid-template-columns: 12fr;
    grid-column-gap: 0px;
  }
}
